import { gql, useMutation } from "@apollo/client";
import { UserDTO } from "../Auth/UserDTO";

const UPDATE_EMAIL = gql`
  mutation updateEmail($email: String!) {
    updateEmail(email: $email) {
      id
      email
    }
  }
`;

type UpdateEmailResponse = {
  updateEmail: UserDTO;
};

type UpdateEmailInput = {
  email: string;
};
export function useUpdateEmail() {
  const [updateEmailMutation, { loading }] = useMutation<
    UpdateEmailResponse,
    UpdateEmailInput
  >(UPDATE_EMAIL);

  async function updateEmail(email: string) {
    await updateEmailMutation({
      variables: {
        email,
      },
    });
  }

  return {
    loading,
    updateEmail,
  };
}

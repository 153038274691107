import { Menu } from "../UI/Menu";
import { MenuLink } from "../UI/MenuLink";
import { CreditCardIcon } from "../UI/Icons/CreditCardIcon";
import { useMenuCtx } from "./useMenuCtx";
import { useTranslation } from "react-i18next";

export function JustMeetMenu() {
  const { closeMenu, openedOnMobile } = useMenuCtx();
  const { t } = useTranslation();
  return (
    <Menu openedOnMobile={openedOnMobile} onClose={closeMenu}>
      <MenuLink
        onNavigate={closeMenu}
        icon={<CreditCardIcon className={"h-5 w-5"} />}
        isActive={true}
        href={"/payments"}
        label={t("menu.payments")}
      />
    </Menu>
  );
}

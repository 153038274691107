import React, { useMemo } from "react";
import { BrowserRouter, Navigate, Route } from "react-router-dom";
import { LoginScreen } from "./Auth/LoginScreen";
import { ApolloProvider } from "@apollo/client";
import { ApolloClientFactory } from "./ApolloClient/ApolloClientFactory";
import { AuthCtxProvider } from "./Auth/AuthCtxProvider";
import { ProtectedRoute } from "./Auth/ProtectedRoute";
import { DashboardScreen } from "./Dashboard/DashboardScreen";
import { useAuthCtx } from "./Auth/useAuthCtx";
import { OnlyNotLoggedRoute } from "./Auth/OnlyNotLoggedRoute";
import { PaymentScreen } from "./Payments/PaymentScreen";
import { EnterEmailScreen } from "./User/EnterEmailScreen";
import { ProtectedRouteWithoutEmail } from "./Auth/ProtectedRouteWithoutEmail";
import { RefreshUrlScreen } from "./Payments/RefreshUrlScreen";
import { TestScreen } from "./Test/TestScreen";
import { RouteWithoutI18n } from "./I18n/RouteWithoutI18n";
import { AuthByTokenScreen } from "./Auth/AuthByTokenScreen";
import { LocalizeRoutes } from "./I18n/LocalizeRoutes";

function RouteDefinitionComponent() {
  const { user } = useAuthCtx();
  return (
    <LocalizeRoutes>
      <Route
        path="/login"
        element={
          <OnlyNotLoggedRoute user={user} ifLoggedRedirectTo={"/"}>
            <LoginScreen />
          </OnlyNotLoggedRoute>
        }
      />
      <Route path="/lwt" element={<AuthByTokenScreen />} />
      <Route
        path={"/"}
        element={
          <ProtectedRoute user={user}>
            <DashboardScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path={"/stripe/connected"}
        element={
          <ProtectedRoute user={user}>
            <Navigate to={"/payments"} />
          </ProtectedRoute>
        }
      />
      <Route
        path={"/stripe/refresh"}
        element={
          <ProtectedRoute user={user}>
            <RefreshUrlScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path={"/insert-email"}
        element={
          <ProtectedRouteWithoutEmail user={user}>
            <EnterEmailScreen />
          </ProtectedRouteWithoutEmail>
        }
      />
      <Route
        path={"/payments"}
        element={
          <ProtectedRoute user={user}>
            <PaymentScreen />
          </ProtectedRoute>
        }
      />
      <RouteWithoutI18n>
        <Route path={"/test"} element={<TestScreen />} />
      </RouteWithoutI18n>
    </LocalizeRoutes>
  );
}

function App() {
  const client = useMemo(() => {
    return new ApolloClientFactory().create();
  }, []);
  return (
    <ApolloProvider client={client}>
      <AuthCtxProvider>
        <BrowserRouter>
          <RouteDefinitionComponent />
        </BrowserRouter>
      </AuthCtxProvider>
    </ApolloProvider>
  );
}

export default App;

import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";

const CONNECT_STRIPE = gql`
  mutation startAccountConnect {
    startAccountConnect {
      redirectUrl
    }
  }
`;

type ConnectStripeResponse = {
  startAccountConnect: {
    redirectUrl: string;
  };
};

export function useConnectStripe() {
  const [startConnectMutation, { loading }] =
    useMutation<ConnectStripeResponse>(CONNECT_STRIPE);

  const connectStripe = useCallback(async (): Promise<string> => {
    const res = await startConnectMutation();
    const url = res.data?.startAccountConnect?.redirectUrl;
    if (!url) throw new Error("No redirect url");
    return url;
  }, [startConnectMutation]);

  return {
    connectStripe,
    loading,
  };
}

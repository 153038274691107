import { PrimarySolidButton } from "../UI/PrimarySolidButton";

export function TestScreen() {
  return (
    <PrimarySolidButton
      label={"Test error"}
      onClick={() => {
        throw new Error("Test error");
      }}
    />
  );
}

import {
  ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { useCallback, useEffect, useRef, useState } from "react";
import { getFirebaseAuth } from "../Firebase/getFirebaseAuth";
import { TextInput } from "../UI/TextInput";
import { Layout } from "../Layout/Layout";
import { Card } from "../UI/Card";
import { PrimarySolidButton } from "../UI/PrimarySolidButton";
import { useTranslation } from "react-i18next";

type LProps = {
  onCodeSent: (confirmationResult: ConfirmationResult) => void;
};
export function LoginWithPhone(props: LProps) {
  const { t, i18n } = useTranslation();
  const auth = getFirebaseAuth();
  const [verifier, setVerifier] = useState<RecaptchaVerifier | null>(null);
  const recaptchaContainer = useRef<HTMLDivElement>(null);
  const [sendingCode, setSendingCode] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(
    i18n.language === "it" ? "+39" : ""
  );
  const [showPhoneError, setShowPhoneError] = useState(false);

  useEffect(() => {
    if (!recaptchaContainer.current) throw new Error("No recaptcha container");
    setVerifier(
      new RecaptchaVerifier(
        recaptchaContainer.current,
        {
          size: "invisible",
        },
        auth
      )
    );
  }, [auth]);

  useEffect(() => {
    if (phoneNumber.length > 0 && !phoneNumber.startsWith("+"))
      setShowPhoneError(true);
    else setShowPhoneError(false);
  }, [phoneNumber]);

  async function sendCode() {
    if (phoneNumber.length < 7) return setShowPhoneError(true);
    if (!phoneNumber.startsWith("+")) return setShowPhoneError(true);
    if (!verifier) throw new Error("No verifier");
    try {
      setSendingCode(true);
      const res = await signInWithPhoneNumber(auth, phoneNumber, verifier);
      props.onCodeSent(res);
    } finally {
      setSendingCode(false);
    }
  }
  return (
    <>
      <div ref={recaptchaContainer} />
      <Card>
        <div
          className={
            "sm:min-w-[200px] md:min-w-[500px] flex items-center justify-center flex-col"
          }
        >
          <h1 className={"text-center mb-4 font-bold text-xl"}>
            {t("login.loginTitle")}
          </h1>
          <TextInput
            autoFocus
            errorString={
              showPhoneError ? t("login.insertAValidNumber") : undefined
            }
            onEnterPressed={sendCode}
            value={phoneNumber}
            onChange={(v) => setPhoneNumber(v)}
            center={true}
            placeholder={t("login.yourNumber")}
          />
          <div className={"mt-4"}>
            <PrimarySolidButton
              onClick={sendCode}
              loading={sendingCode}
              label={t("login.sendCode")}
            />
          </div>
        </div>
      </Card>
    </>
  );
}

type IProps = {
  confirmResult: ConfirmationResult;
};
function InsertCode({ confirmResult }: IProps) {
  const [code, setCode] = useState("");
  const [confirming, setConfirming] = useState(false);
  const [showError, setShowError] = useState(false);
  const { t } = useTranslation();
  const confirmCode = useCallback(async () => {
    if (code.length < 6) return;
    try {
      setConfirming(true);
      await confirmResult.confirm(code);
      window.location.href = "/";
    } catch (e) {
      setShowError(true);
    } finally {
      setConfirming(false);
    }
  }, [code, confirmResult]);

  useEffect(() => {
    (async function () {
      if (code.length === 6) await confirmCode();
    })();
  }, [code, confirmCode]);

  return (
    <Card>
      <div
        className={
          "sm:min-w-[200px] md:min-w-[500px] flex items-center justify-center flex-col"
        }
      >
        <h1 className={"text-center mb-4 font-bold text-xl"}>
          {t("login.verificationCodeTitle")}
        </h1>
        <TextInput
          errorString={showError ? t("login.invalidCode") : undefined}
          onEnterPressed={confirmCode}
          center={true}
          placeholder={t("login.yourCode")}
          value={code}
          onChange={(v) => {
            setShowError(false);
            setCode(v);
          }}
        />
        <div className={"mt-4"}>
          <PrimarySolidButton
            onClick={confirmCode}
            loading={confirming}
            label={t("login.confirm")}
          />
        </div>
      </div>
    </Card>
  );
}

export function LoginScreen() {
  const [confirmResult, setConfirmResult] = useState<ConfirmationResult>();
  return (
    <Layout>
      <div className={"flex flex-1 justify-center items-center w-full p-4"}>
        {confirmResult ? (
          <InsertCode confirmResult={confirmResult} />
        ) : (
          <LoginWithPhone
            onCodeSent={(confirm) => {
              setConfirmResult(confirm);
            }}
          />
        )}
      </div>
    </Layout>
  );
}

import { Layout } from "../Layout/Layout";
import { DividerLine } from "../UI/DividerLine";
import {
  StripeAccountState,
  useStripeAccountState,
} from "./useStripeAccountState";
import { Card } from "../UI/Card";
import { useConnectStripe } from "./useConnectStripe";
import { WhiteLoadingIndicator } from "../UI/WhiteLoadingIndicator";
import { useTranslation } from "react-i18next";

function StripeAccountConnected() {
  const { t } = useTranslation();
  return (
    <div>
      <p className={"text-gray-700"}>{t("payments.alreadyConnectedStripe")}</p>
    </div>
  );
}

function ConnectStripeAccount() {
  const { connectStripe, loading } = useConnectStripe();
  const { t } = useTranslation();
  async function startConnecting() {
    window.location.href = await connectStripe();
  }
  return (
    <div>
      <p
        className={"text-gray-700"}
        dangerouslySetInnerHTML={{
          __html: t("payments.connectStripeCopy"),
        }}
      />
      <button
        onClick={startConnecting}
        className={
          "bg-[#635bff] mt-4 text-white px-6 py-2 rounded-lg flex items-center"
        }
      >
        <span>{t("payments.connectStripeCta")}</span>
        {loading ? (
          <span className={"ml-2"}>
            <WhiteLoadingIndicator size={"small"} />
          </span>
        ) : null}
      </button>
    </div>
  );
}

function NeedMoreInfoOnStripe() {
  const { t } = useTranslation();
  return (
    <div>
      <p className={"text-gray-700"}>{t("payments.needMoreInfo")}</p>
      <button
        onClick={() =>
          (window.location.href = "https://dashboard.stripe.com/login")
        }
        className={
          "bg-[#635bff] mt-4 text-white px-6 py-2 rounded-lg flex items-center"
        }
      >
        <span>{t("payments.goToStripe")}</span>
      </button>
    </div>
  );
}

export function PaymentScreen() {
  const { state } = useStripeAccountState();

  const { t } = useTranslation();
  return (
    <Layout showMenu>
      <div className={"p-6 w-full"}>
        <h1 className={"font-bold text-2xl"}>{t("payments.title")}</h1>
        <DividerLine />
        <Card>
          <h2 className={"font-bold text-xl"}>Stripe</h2>
          <div className={"mt-2"}>
            {state === StripeAccountState.NEED_CONNECTION ? (
              <ConnectStripeAccount />
            ) : state === StripeAccountState.CONNECTED ? (
              <StripeAccountConnected />
            ) : (
              <NeedMoreInfoOnStripe />
            )}
          </div>
        </Card>
      </div>
    </Layout>
  );
}

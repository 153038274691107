import React, { PropsWithChildren } from "react";
import { RouteWithoutI18n } from "./RouteWithoutI18n";
import { PathRouteProps } from "react-router/lib/components";
import { LanguageSetterComponent } from "./LanguageSetterComponent";
import { Route, Routes } from "react-router-dom";
import { RedirectToDefaultLocale } from "./RedirectToDefaultLocale";

type Props = PropsWithChildren<unknown>;

export function LocalizeRoutes(props: Props) {
  const newRoutes: React.ReactNode[] = [];

  React.Children.forEach(props.children, (route, index) => {
    if (!React.isValidElement(route) || typeof route.type === "string") {
      return newRoutes.push(route);
    }

    if (route.type.name === RouteWithoutI18n.name) {
      newRoutes.push(
        React.cloneElement<PathRouteProps>(route.props.children, {
          key: `${index}-base`,
        })
      );
      return;
    }
    const props = route.props as PathRouteProps;
    const path = props.path;
    newRoutes.push(
      React.cloneElement<PathRouteProps>(route, {
        path: `/:lang${path}`,
        key: `${index}-localized`,
        element: (
          <LanguageSetterComponent>{props.element}</LanguageSetterComponent>
        ),
      })
    );
    newRoutes.push(
      <Route
        key={`${index}-base`}
        path={path}
        element={<RedirectToDefaultLocale path={path} />}
      />
    );
  });

  return <Routes>{newRoutes}</Routes>;
}

type Props = {
  placeholder?: string;
  center?: boolean;
  value: string;
  onChange: (value: string) => void;
  onEnterPressed?: () => void;
  errorString?: string;
  autoFocus?: boolean;
  autocapitalize?: string;
};
export function TextInput(props: Props) {
  return (
    <div className={"flex flex-col items-center max-w-[400px]"}>
      <input
        autoCapitalize={props.autocapitalize}
        autoFocus={props.autoFocus}
        onKeyDown={(e) => {
          if (e.key === "Enter" && props.onEnterPressed) {
            props.onEnterPressed();
          }
        }}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        value={props.value}
        placeholder={props.placeholder}
        className={[
          "border rounded-md h-10 md:min-w-[300px] p-2",
          props.center ? "text-center" : "",
          props.errorString ? "border-red-600" : "border-gray-200",
        ].join(" ")}
      />
      {props.errorString ? (
        <span
          className={
            "text-red-600 text-sm text-center mt-2 flex-shrink flex-grow-0"
          }
        >
          {props.errorString}
        </span>
      ) : null}
    </div>
  );
}

export const it = {
  exit: "Esci",
  menu: {
    payments: "Configura pagamenti",
  },
  payments: {
    title: "Configura pagamenti",
    connectStripeCopy:
      'JustMeet si integra con <a class="text-blue-600 underline" href="https://stripe.com">Stripe</a> per permettere ai tuoi clienti di pagare la loro prevendita in anticipo.<br />Per abilitare Stripe, sono necessari pochi e semplici passi, clicca sul bottone qui sotto per iniziare',
    connectStripeCta: "Connetti Stripe",
    alreadyConnectedStripe:
      "Hai già connesso il tuo account Stripe con JustMeet e sei in grado di ricevere i pagamenti",
    needMoreInfo:
      "Hai connesso l'account di stripe, ma sono necessari gli ultimi passaggi per attivare i pagamenti. Puoi completare la procedura seguendo le istruzioni che trovi nel tuo account Stripe",
    goToStripe: "Vai a Stripe",
  },
  login: {
    loginTitle: "Accedi",
    yourNumber: "Il tuo numero di telefono",
    insertAValidNumber:
      "Devi inserire un numero di telefono valido, compreso di prefisso. Ad esempio +391234567890",
    sendCode: "Invia codice",
    verificationCodeTitle: "Codice di verifica",
    yourCode: "Il codice che hai ricevuto",
    confirm: "Conferma",
    invalidCode: "Il codice inserito non è valido",
  },
  insertYourEmail: {
    title: "Inserisci la tua email",
    yourEmail: "La tua email",
    save: "Salva",
    insertAValidEmail: "Inserisci un email valida",
  },
};

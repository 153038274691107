import { UserDTO } from "./UserDTO";
import { Navigate } from "react-router-dom";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  user?: UserDTO;
}>;
export function ProtectedRouteWithoutEmail(props: Props) {
  if (!props.user) return <Navigate to={"/login"} />;
  if (props.user.email) return <Navigate to={"/"} />;
  return <>{props.children ?? null}</>;
}

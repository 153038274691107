import { useParams, useSearchParams } from "react-router-dom";
import { FullScreenLoading } from "../UI/FullScreenLoading";
import { useEffect } from "react";
import { useExchangeIdTokenWithCustomToken } from "./useExchangeIdTokenWithCustomToken";
import { getFirebaseAuth } from "../Firebase/getFirebaseAuth";
import { signInWithCustomToken, signOut } from "firebase/auth";
import { decode } from "base-64";
export function AuthByTokenScreen() {
  const [search] = useSearchParams();
  const token = search.get("it");
  const redirect = search.get("r");
  const params = useParams();
  const lang = params.lang;
  const { exchangeIdTokenForCustomToken } = useExchangeIdTokenWithCustomToken();
  useEffect(() => {
    (async () => {
      const redirectTo = redirect ? decode(redirect) : `/${lang}`;
      if (!token) return;
      const customToken = await exchangeIdTokenForCustomToken(token);
      await signOut(getFirebaseAuth());
      await signInWithCustomToken(getFirebaseAuth(), customToken);
      window.location.href = redirectTo;
    })();
  }, [lang, token, exchangeIdTokenForCustomToken, redirect]);

  if (!token) return <p>Missing token</p>; //TODO notify user

  return <FullScreenLoading />;
}

import { useParams } from "react-router-dom";
import { PropsWithChildren, useEffect } from "react";
import { useTranslation } from "react-i18next";
type Props = PropsWithChildren<unknown>;
export function LanguageSetterComponent(props: Props) {
  const { i18n } = useTranslation();
  const params = useParams();
  useEffect(() => {
    const locale = params.lang as string;
    console.log("Params has changed");
    if (locale && i18n.language !== locale) {
      console.log(`Setting locale to`, locale);
      i18n.changeLanguage(locale).then(() => {
        console.log("Locale setted");
      });
    }
  }, [params, i18n]);
  return <>{props.children}</>;
}

import { FirebaseApp, getApp, initializeApp } from "firebase/app";
import { FirebaseAppName } from "./FirebaseAppName";

function safelyGetApp(): FirebaseApp | undefined {
  try {
    return getApp(FirebaseAppName);
  } catch (e) {
    return;
  }
}
export function initializeFirebase(): FirebaseApp {
  const oldApp = safelyGetApp();
  if (oldApp) {
    console.log(
      `Firebase app ${FirebaseAppName} already initialized. Skipping initialization`
    );
    return oldApp;
  }
  console.log(`Initializing Firebase app ${FirebaseAppName}`);
  console.log(
    "process.env.REACT_APP_JUSTMEET_FIREBASE_CONFIG",
    process.env.REACT_APP_JUSTMEET_FIREBASE_CONFIG
  );
  const firebaseConfig = process.env.REACT_APP_JUSTMEET_FIREBASE_CONFIG
    ? JSON.parse(process.env.REACT_APP_JUSTMEET_FIREBASE_CONFIG)
    : {
        apiKey: "AIzaSyDfTFmPfnrl3ml8vK5NC2_BVRNfw9FQE9E",
        authDomain: "dating-app-21650.firebaseapp.com",
        databaseURL:
          "https://dating-app-21650-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "dating-app-21650",
        storageBucket: "dating-app-21650.appspot.com",
        messagingSenderId: "365234398413",
        appId: "1:365234398413:web:e1fe0ff1e380553c507584",
        measurementId: "G-JYKRW0CQ1R",
      };

  return initializeApp(firebaseConfig, FirebaseAppName);
}

import { Navigate, useSearchParams } from "react-router-dom";

const DEFAULT_LOCALE = "it";
type Props = {
  path: string;
};
export function RedirectToDefaultLocale(props: Props) {
  const [searchParams] = useSearchParams();
  const query = searchParams.toString();
  const pWithoutHeadSlash = props.path.replace(/^\//, "");
  const parts = [`/${DEFAULT_LOCALE}`];
  if (pWithoutHeadSlash.length > 0) parts.push(pWithoutHeadSlash);
  let r = parts.join("/");
  if (query.length > 0) r += `?${query}`;
  console.log("Redirecting to default locale page:", r);
  return <Navigate to={r} />;
}

import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";

const EXCHANGE_CUSTOM_TOKEN_WITH_ID = gql`
  mutation exchangeIdTokenForCustomToken($idToken: String!) {
    exchangeIdTokenForCustomToken(idToken: $idToken)
  }
`;

type ExchangeIdTokenResponse = {
  exchangeIdTokenForCustomToken: string;
};

type ExchangeIdTokenInput = {
  idToken: string;
};
export function useExchangeIdTokenWithCustomToken() {
  const [exchangeIdTokenMutation, { loading }] = useMutation<
    ExchangeIdTokenResponse,
    ExchangeIdTokenInput
  >(EXCHANGE_CUSTOM_TOKEN_WITH_ID);

  const exchangeIdTokenForCustomToken = useCallback(
    async (idToken: string) => {
      const res = await exchangeIdTokenMutation({
        variables: {
          idToken,
        },
      });
      const token = res.data?.exchangeIdTokenForCustomToken;
      if (!token) throw new Error("No token returned");
      return token;
    },
    [exchangeIdTokenMutation]
  );

  return {
    exchangeIdTokenForCustomToken,
    loading,
  };
}

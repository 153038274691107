import { PropsWithChildren } from "react";

type Props = PropsWithChildren<unknown>;
export function Card(props: Props) {
  return (
    <div className={"rounded-3xl p-6 shadow-lg shadow-slate-300/60"}>
      {props.children ?? null}
    </div>
  );
}

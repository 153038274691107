export const en = {
  exit: "Exit",
  menu: {
    payments: "Configure payments",
  },
  payments: {
    title: "Configure payments",
    connectStripeCopy:
      'JustMeet integrates <a class="text-blue-600 underline" href="https://stripe.com">Stripe</a> to let your customer pay their ticket in advance.<br />To enable Stripe, To enable Stripe, a few steps are required, click on the button below to get started',
    connectStripeCta: "Connect Stripe",
    alreadyConnectedStripe:
      "You have already connected your Stripe account with JustMeet and are able to receive payments",
    needMoreInfo:
      "You connected the stripe account, but the final steps are required to activate the payments. You can complete the procedure by following the instructions you find in your Stripe account",
    goToStripe: "Go to Stripe",
  },
  login: {
    loginTitle: "Log In",
    yourNumber: "Your mobile number",
    insertAValidNumber:
      "You must enter a valid phone number, including country code. For example +391234567890",
    sendCode: "Send code",
    verificationCodeTitle: "Verification code",
    yourCode: "The code you received",
    confirm: "Confirm",
    invalidCode: "The code you entered is invalid",
  },
  insertYourEmail: {
    title: "Insert your email",
    yourEmail: "Your email",
    save: "Save",
    insertAValidEmail: "Please enter a valid email",
  },
};

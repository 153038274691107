import { PropsWithChildren } from "react";
import { CloseIcon } from "./Icons/CloseIcon";

type Props = PropsWithChildren<{
  openedOnMobile: boolean;
  onClose: () => void;
}>;
export function Menu(props: Props) {
  return (
    <div>
      <aside
        className={[
          "h-screen overflow-y-auto fixed inset-0 md:sticky flex-shrink-0 md:w-64 md:block bg-white border-r border-slate-200 p-4",
          props.openedOnMobile ? "block" : "hidden",
        ].join(" ")}
      >
        <div className={"flex justify-between mb-4 "}>
          <img className={"w-32"} src={"/new-logotype.png"} alt={"JustMeet"} />
          <CloseIcon
            onClick={props.onClose}
            className={"h-6 w-6 text-gray-600 md:hidden"}
          />
        </div>
        {props.children ?? null}
      </aside>
    </div>
  );
}

import { gql, useQuery } from "@apollo/client";

const STRIPE_ACCOUNT_STATE = gql`
  query stripeAccountState {
    stripeAccountState {
      state
    }
  }
`;

export enum StripeAccountState {
  CONNECTED = "CONNECTED",
  NEED_CONNECTION = "NEED_CONNECTION",
  NEED_ENABLE_CHARGES = "NEED_ENABLE_CHARGES",
  NEED_ENABLE_PAYOUTS = "NEED_ENABLE_PAYOUTS",
}

type StripeAccountStateResponse = {
  stripeAccountState: {
    state: StripeAccountState;
  };
};

export function useStripeAccountState() {
  const { data, loading } =
    useQuery<StripeAccountStateResponse>(STRIPE_ACCOUNT_STATE);

  return {
    state: data?.stripeAccountState.state,
    loading,
  };
}

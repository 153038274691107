import { FullScreenLoading } from "../UI/FullScreenLoading";
import { useConnectStripe } from "./useConnectStripe";
import { useEffect, useRef } from "react";

export function RefreshUrlScreen() {
  const { connectStripe } = useConnectStripe();
  const refreshed = useRef(false);

  useEffect(() => {
    (async function refreshStripeLink() {
      if (refreshed.current) return;
      window.location.href = await connectStripe();
      refreshed.current = true;
    })();
  }, [connectStripe]);

  return <FullScreenLoading />;
}

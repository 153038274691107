import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getIdTokenAsync } from "../Firebase/getIdTokenAsync";

export class ApolloClientFactory {
  public create(): ApolloClient<NormalizedCacheObject> {
    const link = new HttpLink({
      uri: `${process.env.REACT_APP_JUSTMEET_SERVER_BASE_URL}/graphql`,
    });

    const setAuthorizationLink = setContext(async (_, { headers }) => {
      const token = await getIdTokenAsync();
      return {
        headers: {
          ...headers,
          Authorization: token,
        },
      };
    });

    return new ApolloClient({
      link: setAuthorizationLink.concat(link),
      cache: new InMemoryCache(),
    });
  }
}

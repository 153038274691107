import { UserDTO } from "./UserDTO";
import { Navigate } from "react-router-dom";
import { PropsWithChildren } from "react";
import { encode } from "base-64";

type Props = PropsWithChildren<{
  user?: UserDTO;
}>;
export function ProtectedRoute(props: Props) {
  const currentLocation = window.location.href;
  if (!props.user) return <Navigate to={"/login"} />;
  if (!props.user.email)
    return <Navigate to={`/insert-email?r=${encode(currentLocation)}`} />;
  return <>{props.children ?? null}</>;
}

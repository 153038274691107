import { Layout } from "../Layout/Layout";
import { Card } from "../UI/Card";
import { TextInput } from "../UI/TextInput";
import { useState } from "react";
import { PrimarySolidButton } from "../UI/PrimarySolidButton";
import { useUpdateEmail } from "./useUpdateEmail";
import EmailValidator from "email-validator";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { decode } from "base-64";

export function EnterEmailScreen() {
  const [email, setEmail] = useState<string>("");
  const [showInsertAValidEmail, setShowInsertAValidEmail] = useState(false);
  const { loading, updateEmail } = useUpdateEmail();
  const { t } = useTranslation();
  const [search] = useSearchParams();
  const redirect = search.get("r");
  async function saveEmail() {
    const redirectTo = redirect ? decode(redirect) : "/";
    if (!EmailValidator.validate(email)) {
      return setShowInsertAValidEmail(true);
    }
    await updateEmail(email);
    window.location.href = redirectTo;
  }
  return (
    <Layout>
      <div className={"flex items-center justify-center flex-1 md:p-4 p-2"}>
        <Card>
          <div
            className={
              "min-w-[250px] sm:min-w-[300px] md:min-w-[500px] flex items-center justify-center flex-col"
            }
          >
            <h1 className={"text-center mb-4 font-bold text-xl"}>
              {t("insertYourEmail.title")}
            </h1>
            <TextInput
              autocapitalize="off"
              autoFocus
              errorString={
                showInsertAValidEmail
                  ? t("insertYourEmail.insertAValidEmail")
                  : ""
              }
              center={true}
              placeholder={t("insertYourEmail.yourEmail")}
              value={email}
              onChange={(v) => {
                setShowInsertAValidEmail(false);
                setEmail(v?.trim());
              }}
            />
            <div className={"mt-4 flex justify-center"}>
              <PrimarySolidButton
                onClick={saveEmail}
                loading={loading}
                label={t("insertYourEmail.save")}
              />
            </div>
          </div>
        </Card>
      </div>
    </Layout>
  );
}
